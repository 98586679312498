import { ReactNode } from "react";
import ScanCenterService from "../../services/ScanCenter/scanCenter.service";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";

export interface IDropdownOptions {
  label?: string;
  value?: string | number | boolean;
  optionalLabel?: string | ReactNode;
}

export const getDropdownOptions = (
  array: any[],
  labelKey: string,
  valueKey: string
): IDropdownOptions[] => {
  return array.map((object) => ({
    label: object[labelKey],
    value: object[valueKey],
  }));
};

export const setScanTypeOptions = (setterFunction: Function) => {
  ScanCenterService.fetchScanCenters(
    (scanCenters: ScanCenter[]) => {
      setterFunction(getDropdownOptions(scanCenters, "name", "id"));
    },
    () => {},
    () => {}
  );
};
