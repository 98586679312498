import React, { useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { loginFormValidation } from "./LoginValidation";
import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import "./loginForm.scss";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import { Admin } from "../../../models/Admin/admin.model";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};

interface LoginFormProps extends AuthReducerProps {}

function LoginForm(props: LoginFormProps) {
  const history = useHistory();

  const [formLoading, setFormLoading] = useState(false);

  const onSubmit = (values: FormikValues) => {
    setFormLoading(true);
    AuthService.getUserRole(
      values.email,
      (userRole: UserRoleEnum) => {
        switch (userRole) {
          case UserRoleEnum.DOCTOR: {
            const doctor = Object.assign(new Doctor(), values);
            AuthService.doctorLogin(
              doctor,
              () => {
                history.push(AppRoutes.HOME);
              },
              () => {},
              () => {
                setFormLoading(false);
              }
            );
            break;
          }
          case UserRoleEnum.ADMIN: {
            const admin = Object.assign(new Admin(), values);
            AuthService.adminLogin(
              admin,
              () => {
                history.push(AppRoutes.HOME);
              },
              () => {},
              () => {
                setFormLoading(false);
              }
            );
            break;
          }
          case UserRoleEnum.SCAN_CENTER: {
            const scanCenter = Object.assign(new ScanCenter(), values);
            AuthService.scanCenterLogin(
              scanCenter,
              () => {
                history.push(AppRoutes.HOME);
              },
              () => {},
              () => {
                setFormLoading(false);
              }
            );
            break;
          }
        }
      },
      () => {
        setFormLoading(false);
      },
      () => {}
    );
  };

  return (
    <div className="login-form">
      <h1>Login</h1>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={loginFormValidation}
      >
        <Form>
          <InputField type="email" name="email" placeholder="Enter email" />
          <InputField
            type="password"
            name="password"
            placeholder="Enter password"
          />
          <Button htmlType="submit" loading={formLoading}>
            Login
          </Button>
        </Form>
      </Formik>
    </div>
  );
}

export default AuthContainer(LoginForm);
