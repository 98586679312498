import React from "react";
import { ReactComponent as SVG } from "../../../assets/images/progress-bar.svg";
import "./progressBar.scss";

const ProgressBar = ({ progress }: { progress: number }) => {
  const width = `${progress}%`;
  return (
    <div className="progress-bar__wrapper">
      <div className="progress-bar">
        <div className="progess-bar__fill" style={{ width }} />
      </div>
      <div className="progress-bar__percent">{width}</div>
    </div>
  );
};
export default ProgressBar;
