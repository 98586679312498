import { notification, message as MobileNotification } from "antd";
import { ArgsProps } from "antd/lib/message";

interface INotification {
  message: string;
  description?: string;
  type: string;
  duration?: number;
  key?: string;
  closable?: boolean;
}

type notificationType = "success" | "error" | "warning" | "info" | "open";

const Notification = ({
  message,
  description,
  type,
  duration,
  key,
  closable = true,
}: INotification) => {
  if (window.innerWidth <= 768) {
    return MobileNotification[type as notificationType]({
      content: message,
    } as ArgsProps);
  }
  return notification[type as notificationType]({
    message,
    description,
    duration: duration ? duration : 5,
    key,
  });
};

export default Notification;
