import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import caseReducer, { CaseState } from "./caseReducer";

export interface RootReducerProps {
  auth: AuthState;
  caseDetail: CaseState;
}

const RootReducer = combineReducers<RootReducerProps>({
  auth: authReducer,
  caseDetail: caseReducer,
});

export default RootReducer;
