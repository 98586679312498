import React, { useState } from "react";
import "./appHeader.scss";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthService from "../../../services/AuthService/auth.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ApiRoutes from "../../../routes/routeConstants/apiRoutes";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface AppHeaderProps extends AuthReducerProps {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
}
function AppHeader({ authenticated, userRole, user, collapsed, setCollapsed }: AppHeaderProps) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    AuthService.logout(
      userRole === UserRoleEnum.ADMIN
        ? ApiRoutes.ADMIN_SIGN_OUT
        : userRole === UserRoleEnum.SCAN_CENTER
        ? ApiRoutes.SCAN_CENTER_SIGN_OUT
        : ApiRoutes.DOCTOR_SIGN_OUT,
      () => {
        history.push(AppRoutes.LOGIN);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  return (
    authenticated && (
      <div className={`app-header ${collapsed ? 'collapsed' : 'expanded'}`}>
        <div className="app-header__user-info">
          <div className="app-header__user-name">
            {user?.name ? user?.name : `${user?.firstName} ${user?.lastName}`}
          </div>
        </div>
        <Button
          type="primary"
          className="float-right"
          loading={loading}
          onClick={handleLogout}
        >
          <LogoutOutlined />
          Logout
        </Button>
      </div>
    )
  );
}

export default AuthContainer(AppHeader);
