import axios from "axios";
import { store } from "../store";
import {
  REQUEST_LOGOUT,
  SET_USER_ROLE,
  UNAUTHENTICATED,
} from "../store/definitions/authConstants";
import ApiRoutes from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import AuthService from "../services/AuthService/auth.service";
import { UserRoleEnum } from "../enums/userRole.enum";
import { AppRoutes } from "../routes/routeConstants/appRoutes";

export const getHeaders = (): any => {
  let headers, auth;
  if (localStorage.getItem("auth")) {
    auth = JSON.parse(localStorage.getItem("auth") || "");
  }
  headers = {
    ...auth,
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 1800000,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): any => {
    if (
      response.config.url &&
      [
        ApiRoutes.DOCTOR_LOGIN,
        ApiRoutes.ADMIN_LOGIN,
        ApiRoutes.SCAN_CENTER_LOGIN,
      ].includes(response.config.url)
    ) {
      localStorage.setItem("auth", JSON.stringify(response.headers));
    }
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
    if (response.status === 401 || response.status === 403) {
      localStorage.clear();
      store.dispatch({
        type: UNAUTHENTICATED,
        payload: {
          authenticated: false,
        },
      });
      store.dispatch({
        type: SET_USER_ROLE,
        payload: {
          userRole: undefined,
          adminType: undefined,
        },
      });
    }
    const message = response?.data?.error
      ? response?.data?.error
      : response?.status === 502
      ? "Server is under maintenance. Please try after some time!"
      : "Something went wrong. Please try again";
    if (response?.status !== 401) {
      Notification({ message, type: NotificationTypes.ERROR });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
