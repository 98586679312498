import React, { useEffect, useState } from "react";
import "./reportTemplateForm.scss";
import { ReportTemplate } from "../../../models/ReportTemplate/reportTemplate.model";
import { Form, Formik } from "formik";
import { adminFormValidation } from "../../Admins/AdminForm/adminFormValidation";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import faker from "faker";
import { reportTemplateFormValidation } from "./reportTemplateFormValidation";
import ReportTemplateService from "../../../services/ReportTemplate/reportTemplate.service";
import ScanTypeService from "../../../services/ScanType/scanType.service";
import { ScanType } from "../../../models/ScanType/scanType.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../../shared/utils/dropdownUtils";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import DropdownField from "../../../shared/components/DropdownField";
import Editor from "../../../shared/components/Editor";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";

interface ReportTemplateFormProps extends AuthReducerProps {
  reportTemplate: ReportTemplate;
  onSuccess: (reportTemplate: ReportTemplate) => void;
}

function ReportTemplateForm({
  reportTemplate,
  onSuccess,
  userRole,
}: ReportTemplateFormProps) {
  const isEdit =
    reportTemplate.id &&
    !(reportTemplate?.createdByAdminId && userRole === UserRoleEnum.DOCTOR);

  const [formLoading, setFormLoading] = useState(false);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const handleSubmit = (values: ReportTemplate) => {
    const reportTemplate = Object.assign(new ReportTemplate(), values);
    setFormLoading(true);
    if (isEdit) {
      ReportTemplateService.updateReportTemplate(
        reportTemplate,
        (reportTemplate: ReportTemplate) => {
          onSuccess(reportTemplate);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ReportTemplateService.createReportTemplate(
        reportTemplate,
        (reportTemplate: ReportTemplate) => {
          onSuccess(reportTemplate);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="report-template-form">
      <h2 className="text-primary">
        {isEdit ? "Edit Template" : "Add Template"}
      </h2>
      <Formik
        initialValues={reportTemplate}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={reportTemplateFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[30, 0]}>
                <Col span={12}>
                  <InputField
                    title="Name"
                    type="text"
                    name="name"
                    placeholder="Enter name"
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    placeHolder="Select Scan Type"
                    showSearch
                    title="Scan Type"
                    name="scanTypeId"
                    setFieldValue={setFieldValue}
                    options={scanTypeOptions}
                    value={values.scanTypeId}
                  />
                </Col>
                <Col span={24}>
                  <Editor
                    error={errors.content as string}
                    value={values.content || ""}
                    onChangeHandler={(content) => {
                      setFieldValue("content", content);
                    }}
                  />
                </Col>
              </Row>
              <div className="report-template-form__submit-wrapper text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  {isEdit ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(ReportTemplateForm);
