import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as AuthActions from "../actions/authActions";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  return {
    ...ownProps,
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    userRole: state.auth.userRole,
    adminType: state.auth.adminType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch);

const AuthContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default AuthContainer;
