import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { BodyPart } from "../../models/BodyPart/bodyPart.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class BodyPartService {
  static fetchBodyParts(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.BODY_PARTS)
      .then((response) => {
        const bodyParts = deserialize(BodyPart, response.data["body_parts"]);
        onSuccess(bodyParts);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showBodyPart(
    bodyPartId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BODY_PARTS + "/" + bodyPartId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const bodyPart = deserialize(BodyPart, response.data["body_part"]);
        onSuccess(bodyPart);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createBodyPart(
    bodyPart: BodyPart,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const bodyPartJSON = { body_part: serialize(bodyPart) };
    axiosInstance
      .post(ApiRoutes.ADMIN_BODY_PARTS, bodyPartJSON)
      .then((response) => {
        Notification({
          message: "Body part created",
          type: NotificationTypes.SUCCESS,
        });
        const bodyPart = deserialize(BodyPart, response.data["body_part"]);
        onSuccess(bodyPart);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateBodyPart(
    bodyPart: BodyPart,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_BODY_PARTS + "/" + bodyPart.id;
    const bodyPartJSON = { body_part: serialize(bodyPart) };
    axiosInstance
      .put(API_URL, bodyPartJSON)
      .then((response) => {
        Notification({
          message: "Body part updated",
          type: NotificationTypes.SUCCESS,
        });
        const bodyPart = deserialize(BodyPart, response.data["body_part"]);
        onSuccess(bodyPart);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteBodyPart(
    bodyPartId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BODY_PARTS + "/" + bodyPartId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
