import React from "react";
import { Issue } from "../../../models/Issue/issue.model";

interface IssueDetailProps {
  issue: Issue;
}

function IssueDetail(props: IssueDetailProps) {
  return <div className="issue-detail">IssueDetail works</div>;
}

export default IssueDetail;
