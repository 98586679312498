import React, { useState } from "react";
import "./changePasswordForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../InputField";
import { changePasswordFormValidation } from "./changePasswordFormValidation";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import ProfileService from "../../../services/Profile/profile.service";

interface ChangePasswordFormProps extends AuthReducerProps {
  onClose: () => void;
}

function ChangePasswordForm({ onClose, userRole }: ChangePasswordFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const { password } = values;
    if (userRole) {
      setFormLoading(true);
      ProfileService.updatePassword(
        userRole,
        password,
        () => {
          onClose();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="change-password-form">
      <h2 className="text-primary">Change Password</h2>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={changePasswordFormValidation}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 10]}>
                <Col span={12}>
                  <InputField
                    title="New Password"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm your password"
                  />
                </Col>
              </Row>
              <div className="change-password-form__submit-wrapper text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={formLoading}
                >
                  Update Password
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(ChangePasswordForm);
