import * as Yup from "yup";

export const adminFormValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  email: Yup.string()
    .required("Email is required!")
    .email("Email is not valid!"),
  mobileNumber: Yup.string().required("Mobile number is required!"),
  password: Yup.string(),
});
