import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { SummaryFilterParams } from "../../models/SummaryFilterParams/summaryFilterParams.model";
import { Summary } from "../../models/Summary/summary.model";

export default class ScanCenterService {
  static fetchScanCenters(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SCAN_CENTERS)
      .then((response) => {
        const scanCenters = deserialize(
          ScanCenter,
          response.data["scan_centers"]
        );
        onSuccess(scanCenters);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showScanCenter(
    scanCenterId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTERS + "/" + scanCenterId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const scanCenter = deserialize(
          ScanCenter,
          response.data["scan_center"]
        );
        onSuccess(scanCenter);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showScanCenterSummary(
    filterParams: SummaryFilterParams,
    scanCenterId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    const API_URL = ApiRoutes.ADMIN_SCAN_CENTER_SUMMARY.replace(
      ":scanCenterId",
      scanCenterId.toString()
    );
    axiosInstance
      .get(API_URL, {
        params,
      })
      .then((response) => {
        const scanCenterSummary = deserialize(Summary, response.data);
        onSuccess(scanCenterSummary);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createScanCenter(
    scanCenter: ScanCenter,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scanCenterJSON = { scan_center: serialize(scanCenter) };
    axiosInstance
      .post(ApiRoutes.SCAN_CENTER_SIGNUP, scanCenterJSON)
      .then((response) => {
        Notification({
          message: "Scan Center created!",
          type: NotificationTypes.SUCCESS,
        });
        const scanCenter = deserialize(
          ScanCenter,
          response.data["scan_center"]
        );
        onSuccess(scanCenter);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateScanCenter(
    scanCenter: ScanCenter,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTERS + "/" + scanCenter.id;
    const scanCenterJSON = { scan_center: serialize(scanCenter) };
    axiosInstance
      .put(API_URL, scanCenterJSON)
      .then((response) => {
        Notification({
          message: "Scan Center updated!",
          type: NotificationTypes.SUCCESS,
        });
        const scanCenter = deserialize(
          ScanCenter,
          response.data["scan_center"]
        );
        onSuccess(scanCenter);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteScanCenter(
    scanCenterId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTERS + "/" + scanCenterId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
