import { serializable, alias, primitive } from "serializr";

export class ScanTypeBodyPartStat {
  @serializable(alias("scan_type_id", primitive()))
  scanTypeId?: number;

  @serializable(alias("scan_type_name", primitive()))
  scanTypeName?: string;

  @serializable(alias("body_part_id", primitive()))
  bodyPartId?: number;

  @serializable(alias("body_part_name", primitive()))
  bodyPartName?: string;

  @serializable(alias("count", primitive()))
  count: number = 0;
}
