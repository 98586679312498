import React from "react";
import "./reportTemplateDetail.scss";
import { ReportTemplate } from "../../../models/ReportTemplate/reportTemplate.model";

interface ReportTemplateDetailProps {
  reportTemplate: ReportTemplate;
}

function ReportTemplateDetail({ reportTemplate }: ReportTemplateDetailProps) {
  return (
    <div className="report-template-detail">ReportTemplateDetail works!</div>
  );
}

export default ReportTemplateDetail;
