import { serializable, alias, object, list, primitive } from "serializr";

export class Issue {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("raised_by", primitive()))
  raisedBy?: string;

  @serializable(alias("created_by", primitive()))
  createdBy?: string;

  @serializable(alias("status", primitive()))
  status?: string;
}
