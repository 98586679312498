import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";
import {
  SET_CASE_DETAILS_UPDATED,
  SET_NEW_CASE_CREATED,
  SET_PENDING,
  SET_PENDING_CASE_IDS,
  SET_UPLOAD_COMPLETE,
  SET_UPLOAD_IN_PROGRESS,
  SET_UPLOAD_START,
} from "../definitions/caseConstants";

export interface SetPendingCaseIdsAction {
  type: typeof SET_PENDING_CASE_IDS;
  payload: { pendingCaseIds: number[] };
}

export interface SetPendingAction {
  type: typeof SET_PENDING;
  payload: { caseDetailId: number };
}

export interface SetNewCaseReceivedAction {
  type: typeof SET_NEW_CASE_CREATED;
  payload: { newCaseDetail?: CaseDetail };
}

export interface SetCaseDetailsUpdatedAction {
  type: typeof SET_CASE_DETAILS_UPDATED;
  payload: { updatedCaseDetail?: CaseDetail };
}

export interface SetUploadStartAction {
  type: typeof SET_UPLOAD_START;
  payload: { caseDetailId: number };
}

export interface SetUploadInProgressAction {
  type: typeof SET_UPLOAD_IN_PROGRESS;
  payload: { caseDetailId: number };
}
export interface SetUploadCompleteAction {
  type: typeof SET_UPLOAD_COMPLETE;
  payload: { caseDetailId: number };
}

export const setPendingCaseIds = (
  pendingCaseIds: number[]
): SetPendingCaseIdsAction => ({
  type: SET_PENDING_CASE_IDS,
  payload: {
    pendingCaseIds: [...pendingCaseIds],
  },
});

export const setCaseDetailUpdated = (
  updatedCaseDetail: CaseDetail
): SetCaseDetailsUpdatedAction => ({
  type: SET_CASE_DETAILS_UPDATED,
  payload: {
    updatedCaseDetail,
  },
});

export const setNewCaseCreated = (
  newCaseDetail: CaseDetail
): SetNewCaseReceivedAction => ({
  type: SET_NEW_CASE_CREATED,
  payload: {
    newCaseDetail,
  },
});

export const setPending = (caseDetailId: number): SetPendingAction => ({
  type: SET_PENDING,
  payload: {
    caseDetailId,
  },
});

export const setUploadStart = (caseDetailId: number): SetUploadStartAction => ({
  type: SET_UPLOAD_START,
  payload: {
    caseDetailId,
  },
});

export const setUploadInProgress = (
  caseDetailId: number
): SetUploadInProgressAction => ({
  type: SET_UPLOAD_IN_PROGRESS,
  payload: {
    caseDetailId,
  },
});

export const setUploadComplete = (
  caseDetailId: number
): SetUploadCompleteAction => ({
  type: SET_UPLOAD_COMPLETE,
  payload: {
    caseDetailId,
  },
});
