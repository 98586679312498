import React, { useEffect, useState } from "react";
import "./viewAccessForm.scss";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import { CaseDetail } from "../../../../models/CaseDetail/caseDetail.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../../../shared/utils/dropdownUtils";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import DoctorService from "../../../../services/Doctor/doctor.service";
import { Doctor } from "../../../../models/Doctor/doctor.model";
import AdminCaseService from "../../../../services/Case/AdminCase/adminCase.service";

interface ViewAccessFormProps {
  caseDetail: CaseDetail;
  onSuccess: () => void;
}

function ViewAccessForm({ caseDetail, onSuccess }: ViewAccessFormProps) {
  const [formValues, setFormValues] = useState(caseDetail);

  const [formLoading, setFormLoading] = useState(false);

  const [optionLoading, setOptionLoading] = useState(false);

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const handleCaseViews = () => {
    if (caseDetail?.id) {
      AdminCaseService.showCaseViews(
        caseDetail?.id,
        (doctors: Doctor[]) => {
          formValues.viewAccessIds = doctors.map((doctor) => doctor.id || 0);
          setFormValues(Object.assign(new Doctor(), formValues));
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    handleCaseViews();
    setOptionLoading(true);
    DoctorService.fetchDoctors(
      (doctors: Doctor[]) => {
        setDoctorOptions(getDropdownOptions(doctors, "doctorName", "id"));
      },
      () => {},
      () => {
        setOptionLoading(false);
      }
    );
  }, []);

  const handleSubmit = (values: CaseDetail) => {
    const doctors = values?.viewAccessIds?.map((id) =>
      Object.assign(new Doctor(), { id })
    );
    if (doctors && caseDetail?.id) {
      setFormLoading(true);
      AdminCaseService.updateCaseViews(
        doctors,
        caseDetail.id,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="view-access-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <DropdownField
                    loading={optionLoading}
                    placeHolder="Select Doctor"
                    showSearch
                    mode={DropdownModeEnum.MULTIPLE}
                    title="Doctor"
                    name="viewAccessIds"
                    setFieldValue={setFieldValue}
                    options={doctorOptions}
                    value={values.viewAccessIds}
                  />
                </Col>
              </Row>
              <div className="view-access-form__submit-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={formLoading}
                >
                  Give Access
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ViewAccessForm;
