import React, { useEffect, useState, useRef } from "react";
import "./scanCenterCases.scss";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import ScanCenterCaseService from "../../../services/Case/ScanCenterCase/scanCenterCase.service";
import moment from "moment";
import { Button, Col, Menu, Modal, Popover, Row, Table, Tooltip } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import CaseOverview from "../../../shared/components/CaseOverview";
import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import CaseForm from "./CaseForm";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";

interface ScanCenterCasesProps extends CaseReducerProps {}

function ScanCenterCases({
  pendingCaseIds,
  completedCaseIds,
  uploadStartedCaseIds,
  newCaseDetail,
  updatedCaseDetail,
}: ScanCenterCasesProps) {
  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseForm, setShowCaseForm] = useState(false);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      scanCenterBranchId: undefined,
    })
  );
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const handleToggleCaseForm = () => setShowCaseForm(!showCaseForm);

  const handleCloseCaseOverview = () => setShowCaseOverview(false);

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    ScanCenterCaseService.fetchScanCenterCases(
      formValues,
      (caseDetails: CaseDetail[]) => {
        setCaseDetails(caseDetails);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        setActiveCase(caseDetail);
        setShowCaseOverview(true);
      },
    };
  };

  const handleUploadCaseSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    } else {
      caseDetails.unshift(caseDetail);
    }
    setCaseDetails([...caseDetails]);
  };

  const handleAddCase = () => {
    setActiveCase(new CaseDetail());
    handleToggleCaseForm();
  };

  const handleEditCase = (caseDetail: CaseDetail) => () => {
    setActiveCase(caseDetail);
    handleToggleCaseForm();
  };

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleOnChange = (updatedValues: CaseFilterParams) => {
    // Update the form values with new inputs
    const newFormValues = Object.assign(new CaseFilterParams(), updatedValues);
    setFormValues(newFormValues);

    // Clear any existing debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      handleFetchCases(newFormValues); // Call the fetch function with the updated form values
    }, 500); // 500ms debounce delay
  };

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleDownloadCase = (
    caseDetail: CaseDetail,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    if (caseDetail?.id) {
      setDownloadLoading(true);
      setActiveCase(caseDetail);
      ReportService.downloadScanCenterReport(
        fileType,
        caseDetail?.id,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadLoading(false);
          setActiveCase(undefined);
        }
      );
    }
  };

  useEffect(() => {
    if (newCaseDetail) {
      caseDetails.unshift(newCaseDetail);
      setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      const caseIndex = caseDetails.findIndex(
        (caseDetail) => caseDetail.id === updatedCaseDetail.id
      );
      if (caseIndex >= 0) {
        caseDetails[caseIndex] = updatedCaseDetail;
      } else {
        caseDetails.unshift(updatedCaseDetail);
      }
      setCaseDetails([...caseDetails]);
    }
  }, [updatedCaseDetail]);

  useEffect(() => {
    handleFetchCases(formValues);
  }, []);

  const columns = [
    {
      title: "Patient name",
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <span>{date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}</span>
      ),
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body parts",
      dataIndex: "caseBodyParts",
      key: "caseBodyParts",
      render: (caseBodyParts: CaseBodyPart[]) => (
        <div>
          {caseBodyParts.map((bodyPart) => bodyPart.bodyPartName).join(", ")}
        </div>
      ),
    },
    {
      title: "Case Status",
      dataIndex: "caseStatus",
      key: "caseStatus",
      render: (status: string) => {
        const caseStatus =
          status === CaseStatusEnum.ASSIGNED ||
          status === CaseStatusEnum.TO_BE_APPROVED
            ? CaseStatusEnum.REPORT_PENDING
            : status;
        return (
          <span className="text-capitalize">
            {caseStatus.split("_").join(" ")}
          </span>
        );
      },
    },
    {
      title: "Upload Status",
      dataIndex: "uploadStatus",
      key: "uploadStatus",
      render: (status: string, caseDetail: CaseDetail) =>
        caseDetail?.id && (
          <span className="text-capitalize scan-center-cases__status-loader">
            {completedCaseIds?.includes(caseDetail?.id)
              ? "Complete"
              : uploadStartedCaseIds?.includes(caseDetail?.id)
              ? "Upload Started"
              : pendingCaseIds?.includes(caseDetail?.id)
              ? "Pending"
              : status.split("_").join(" ")}
            {caseDetail?.id &&
              (pendingCaseIds?.includes(caseDetail?.id) ||
                uploadStartedCaseIds?.includes(caseDetail?.id)) && (
                <AppLoader loading />
              )}
          </span>
        ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, caseDetail: CaseDetail) => (
        <div onClick={(e) => e.stopPropagation()}>
          {caseDetail?.caseStatus === CaseStatusEnum.REPORT_PENDING && (
            <Tooltip title="Edit Case">
              <Button
                className="ml-2"
                icon={<EditOutlined />}
                onClick={handleEditCase(caseDetail)}
              />
            </Tooltip>
          )}
          {caseDetail?.caseStatus === CaseStatusEnum.REPORTED && (
            <Tooltip title="Download Report">
              <Popover
                destroyTooltipOnHide
                overlayClassName="admin-cases__assignment-popover"
                placement="bottomLeft"
                content={
                  <div>
                    <Menu mode="vertical" className="border-none">
                      <Menu.Item
                        className="navbar-item m-0"
                        key="with_letterhead"
                        onClick={() => {
                          handleDownloadCase(
                            caseDetail,
                            DownloadFileTypeEnum.PDF,
                            true
                          );
                        }}
                      >
                        Download with Letterhead
                      </Menu.Item>
                      <Menu.Item
                        className="navbar-item m-0"
                        key="without_letterhead"
                        onClick={() => {
                          handleDownloadCase(
                            caseDetail,
                            DownloadFileTypeEnum.PDF,
                            false
                          );
                        }}
                      >
                        Download without Letterhead
                      </Menu.Item>
                      <Menu.Item
                        className="navbar-item m-0"
                        key="as_word"
                        onClick={() => {
                          handleDownloadCase(
                            caseDetail,
                            DownloadFileTypeEnum.WORD,
                            false
                          );
                        }}
                      >
                        Download as Word
                      </Menu.Item>
                    </Menu>
                  </div>
                }
                title="Download Report"
                trigger="click"
              >
                <Button
                  loading={activeCase?.id === caseDetail?.id && downloadLoading}
                  icon={<DownloadOutlined />}
                  className="ml-2"
                />
              </Popover>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="scan-center-cases">
      <AppCard>
        <Row>
          <Col span={12}>
            <h2 className="mt-2">Cases</h2>
          </Col>
          <Col span={12} className="text-right">
            <Button type="primary" onClick={handleAddCase}>
              <PlusOutlined /> Add Case
            </Button>
          </Col>
        </Row>
        <CaseFilterForm
          isScanCenter
          formValues={formValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onChange={handleOnChange}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={columns}
          />
        )}
        <Modal
          destroyOnClose
          visible={showCaseOverview}
          className="primary-modal"
          onCancel={handleCloseCaseOverview}
          footer={null}
          maskClosable={false}
        >
          {activeCase && (
            <CaseOverview
              activeCaseId={activeCase?.id}
              onCancel={handleCloseCaseOverview}
            />
          )}
        </Modal>

        <Modal
          destroyOnClose
          visible={showCaseForm}
          className="primary-modal"
          onCancel={handleToggleCaseForm}
          footer={null}
          maskClosable={false}
        >
          {activeCase && (
            <CaseForm
              caseDetail={activeCase}
              onSuccess={handleUploadCaseSuccess}
              onClose={() => setShowCaseForm(false)}
            />
          )}
        </Modal>
      </AppCard>
    </div>
  );
}

export default CaseContainer(ScanCenterCases);
