export const AppRoutes = {
  AUTH: "/auth",
  LOGIN: "/auth/login",
  HOME: "/",
  DASHBOARD: "/dashboard",
  DICOM: "/dicom",
  SCAN_CENTRES: "/scan-centres",
  SCAN_CENTER_SUMMARY: "/scan-centers/:scanCenterId/summary",
  CASES: "/cases",
  CASE_DETAIL: "/cases/:caseId",
  DOCTORS: "/doctors",
  SCAN_TYPES: "/scan-types",
  BODY_PARTS: "/body-parts",
  REPORT_TEMPLATES: "/report-templates",
  DOCTOR_SUMMARY: "/doctors/:doctorId/summary",
  ADMINS: "/admins",
  ISSUES: "/issues",
  PROFILE: "/profile",
  CASE_DUMMY: "/dummyCases",
};
