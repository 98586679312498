import React, { useEffect, useState } from "react";
import AppCard from "../../../shared/components/AppCard";
import { Table, Modal } from "antd";
import "./issueList.scss";
import { IssueService } from "../../../services/Issue/issue.service";
import IssueDetail from "../IssueDetail";
import { Issue } from "../../../models/Issue/issue.model";

interface IssueListProps {}

function IssueList(props: IssueListProps) {
  const [issues, setIssues] = useState<Issue[]>([]);

  const [showIssueDetail, setShowIssueDetail] = useState(false);

  const [activeIssue, setActiveIssue] = useState<Issue>();

  const handleCloseIssueDetail = () => setShowIssueDetail(false);

  useEffect(() => {
    IssueService.fetchIssues(
      (issues: Issue[]) => {
        setIssues(issues);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Issue description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Issue Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Raised By",
      dataIndex: "raisedBy",
      key: "raisedBy",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div className="issue-list">
      <AppCard>
        <h2>All Issues</h2>
        <Table dataSource={issues} columns={columns} />
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        visible={showIssueDetail}
        onCancel={handleCloseIssueDetail}
      >
        {activeIssue && <IssueDetail issue={activeIssue} />}
      </Modal>
    </div>
  );
}

export default IssueList;
