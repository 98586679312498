import faker from "faker";
import { Issue } from "../../models/Issue/issue.model";
import moment from "moment";

export class IssueService {
  static fetchIssues(
    onSuccess: Function,
    onError: () => void,
    onFinal: () => void
  ) {
    onSuccess(
      [...Array(10)].map((x, i) => {
        return Object.assign(new Issue(), {
          id: faker.random.uuid(),
          description: faker.lorem.sentence(),
          type: "Delayed Report",
          raisedBy: faker.name.findName(),
          createdBy: moment(faker.date.past()).format("DD/MM/YYYY"),
          status: "resolved",
        });
      })
    );
  }
}
