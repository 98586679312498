import { serializable, alias, object, list, primitive } from "serializr";

export class ScanCenter {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("admin_id", primitive()))
  adminId?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(alias("password", primitive()))
  password?: string;
}
