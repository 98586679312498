import { UserRoleEnum } from "../../enums/userRole.enum";
import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";
import { CaseStatusEnum } from "../../enums/caseStatus.enum";

export const hasDoctorAccessToCase = (
  userRole: string | undefined,
  caseDetail?: CaseDetail
): boolean => {
  return (
    userRole === UserRoleEnum.DOCTOR &&
    (caseDetail?.status === CaseStatusEnum.VIEW ||
      caseDetail?.status === CaseStatusEnum.ASSIGNED ||
      caseDetail?.status === CaseStatusEnum.REPORTED)
  );
};
