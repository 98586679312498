import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SummaryFilterParams } from "../../../models/SummaryFilterParams/summaryFilterParams.model";
import { Summary } from "../../../models/Summary/summary.model";
import AppCard from "../../../shared/components/AppCard";
import SummaryFilterForm from "../../../shared/components/SummaryFilterForm";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import AppLoader from "../../../shared/components/AppLoader";
import { Table } from "antd";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";

interface ScanCenterSummaryProps {}

interface ScanCenterSummaryParams {
  scanCenterId: string;
}

function ScanCenterSummary(props: ScanCenterSummaryProps) {
  const { scanCenterId }: ScanCenterSummaryParams = useParams();

  const [loading, setLoading] = useState(false);

  const [scanCenterSummary, setScanCenterSummary] = useState<Summary>();

  const [formValues, setFormValues] = useState<SummaryFilterParams>(
    new SummaryFilterParams()
  );

  const columns = [
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body Part Type",
      dataIndex: "bodyPartName",
      key: "bodyPartName",
    },
    {
      title: "No of cases",
      dataIndex: "count",
      key: "count",
    },
  ];

  const handleSubmit = (values: SummaryFilterParams) => {
    const formValues = Object.assign(new SummaryFilterParams(), values);
    setFormValues(formValues);
    handleShowScanCenterSummary(formValues);
  };

  const handleReset = () => {
    const formValues = new SummaryFilterParams();
    setFormValues(formValues);
    handleShowScanCenterSummary(formValues);
  };

  const handleShowScanCenterSummary = (formValues: SummaryFilterParams) => {
    if (scanCenterId) {
      setLoading(true);
      ScanCenterService.showScanCenterSummary(
        formValues,
        Number(scanCenterId),
        (scanCenterSummary: Summary) => {
          setScanCenterSummary(scanCenterSummary);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleShowScanCenterSummary(formValues);
  }, []);

  return (
    <div className="scan-center-summary">
      <AppCard>
        <h2>Scan Center Summary</h2>
        <div className="doctor-summary__header">
          <SummaryFilterForm
            type={UserRoleEnum.SCAN_CENTER}
            formValues={formValues}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </div>
        {loading ? (
          <AppLoader loading={loading} />
        ) : scanCenterSummary?.scanTypeBodyPartStats ? (
          <div>
            <div>
              <h3>Total count : {scanCenterSummary?.totalCount}</h3>
            </div>
            <Table
              dataSource={scanCenterSummary.scanTypeBodyPartStats}
              columns={columns}
            />
          </div>
        ) : null}
      </AppCard>
    </div>
  );
}

export default ScanCenterSummary;
