import React from "react";
import "./appCard.scss";

interface AppCardProps {
  children: React.ReactNode;
}

function AppCard(props: AppCardProps) {
  return <div className="app-card">{props && props.children}</div>;
}

export default AppCard;
