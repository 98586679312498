import * as Yup from "yup";

export const scanCenterFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  email: Yup.string()
    .required("Email is required!")
    .email("Email is not valid!"),
  mobileNumber: Yup.string().required("Mobile number is required!"),
  password: Yup.string(),
  address: Yup.string().required("Address is required!"),
});
