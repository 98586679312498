import { serializable, alias, primitive } from "serializr";

export class ReportTemplate {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("scan_type_id", primitive()))
  scanTypeId?: number;

  @serializable(alias("created_by_doctor_id", primitive()))
  createdByDoctorId?: number;

  @serializable(alias("created_by_admin_id", primitive()))
  createdByAdminId?: number;

  @serializable(alias("scan_type_name", primitive()))
  scanTypeName?: string;

  @serializable(alias("content", primitive()))
  content?: string;
}
