import { alias, list, object, primitive, serializable } from "serializr";
import { ScanTypeBodyPartStat } from "../ScanTypeBodyPartStat/scanTypeBodyPartStat.model";

export class Summary {
  @serializable(
    alias("scan_types_body_parts_stats", list(object(ScanTypeBodyPartStat)))
  )
  scanTypeBodyPartStats: ScanTypeBodyPartStat[] = [];

  @serializable(alias("total_count", primitive()))
  totalCount: number = 0;
}
