import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";

export class Doctor {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("admin_id", primitive()))
  adminId?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("uid", primitive()))
  uid?: string;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("provider", primitive()))
  provider?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("allow_password_change", primitive()))
  allowPasswordChange?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("doctor_name", primitive()))
  doctorName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(alias("specialisation", primitive()))
  specialisation?: string;

  @serializable(alias("medical_council_registration_number", primitive()))
  medicalCouncilRegistrationNumber?: string;

  @serializable(alias("state_of_registration", primitive()))
  stateOfRegistration?: string;

  @serializable(alias("case_count_for_current_week", primitive()))
  caseCountForCurrentWeek?: number;

  @serializable(alias("case_count_for_today", primitive()))
  caseCountForToday?: number;

  @serializable(alias("assignments_per_week", primitive()))
  assignmentsPerWeek?: number;

  @serializable(alias("doctor_signature_url", primitive()))
  doctorSignatureUrl?: string;

  @serializable(alias("approval_required", primitive()))
  approvalRequired?: boolean;

  @serializable(
    alias(
      "doctor_signature",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  doctorSignature?: File;

  @serializable(alias("add_signature_to_report", primitive()))
  addSignatureToReport?: boolean = true;

  @serializable(alias("email_notification", primitive()))
  emailNotification?: boolean = true;

  @serializable(alias("telegram_notification", primitive()))
  telegramNotification?: boolean = true;

  fullName?: string;

  getFullName() {
    return this.firstName + " " + this.lastName;
  }
}
