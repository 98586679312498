import axiosInstance from "../../interceptor/axiosInstance";
import { Doctor } from "../../models/Doctor/doctor.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { Summary } from "../../models/Summary/summary.model";
import { SummaryFilterParams } from "../../models/SummaryFilterParams/summaryFilterParams.model";
import { Admin } from "../../models/Admin/admin.model";
import { UserRoleEnum } from "../../enums/userRole.enum";

export default class DoctorService {
  static fetchDoctors(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DOCTORS)
      .then((response) => {
        const doctors = deserialize(Doctor, response.data["doctors"]);
        onSuccess(doctors);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAssignmentDoctors(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ASSIGNMENT_DOCTORS)
      .then((response) => {
        let doctors = deserialize(Doctor, response.data["doctors"] as any[]);
        doctors = doctors.map((doctor) => {
          doctor.type = UserRoleEnum.DOCTOR;
          return doctor;
        });
        let admins = deserialize(Doctor, response.data["admins"] as any[]);
        admins = admins.map((admin) => {
          admin.type = UserRoleEnum.ADMIN;
          return admin;
        });
        onSuccess([...doctors, ...admins]);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDoctor(
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTORS + "/" + doctorId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const doctor = deserialize(Doctor, response.data["doctor"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDoctorSummary(
    filterParams: SummaryFilterParams,
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    const API_URL = ApiRoutes.ADMIN_DOCTOR_SUMMARY.replace(
      ":doctorId",
      doctorId.toString()
    );
    axiosInstance
      .get(API_URL, {
        params,
      })
      .then((response) => {
        const doctorSummary = deserialize(Summary, response.data);
        onSuccess(doctorSummary);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createDoctor(
    doctor: Doctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorForm = convertJSONToFormData({ doctor: serialize(doctor) });
    axiosInstance
      .post(ApiRoutes.DOCTOR_SIGNUP, doctorForm)
      .then((response) => {
        Notification({
          message: "Doctor created!",
          type: NotificationTypes.SUCCESS,
        });
        const doctor = deserialize(Doctor, response.data["doctor"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateDoctor(
    doctor: Doctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorJSON = { doctor: serialize(doctor) };
    if (!doctorJSON.doctor.password || doctorJSON.doctor.password === "") {
      delete doctorJSON.doctor.password;
    }
    const doctorForm = convertJSONToFormData(doctorJSON);
    const API_URL = ApiRoutes.DOCTORS + "/" + doctor.id;
    axiosInstance
      .put(API_URL, doctorForm)
      .then((response) => {
        Notification({
          message: "Doctor updated!",
          type: NotificationTypes.SUCCESS,
        });
        const doctor = deserialize(Doctor, response.data["doctor"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteDoctor(
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTORS + "/" + doctorId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
