import { serializable, alias, primitive, list, object } from "serializr";
import { DicomData } from "../DicomData/dicomData.model";

export class DicomDataThumbnail {
  @serializable(alias("case_detail_id", primitive()))
  caseDetailId?: string;

  @serializable(alias("file_count", primitive()))
  fileCount?: string;

  @serializable(alias("signed_url", primitive()))
  signedUrl?: string;

  @serializable(alias("item_id", primitive()))
  itemId?: string;

  @serializable(alias("folder", primitive()))
  folder?: string;

  isLocked: boolean = true;

  isLoading: boolean = false;

  @serializable(alias("files", list(object(DicomData))))
  files: DicomData[] = [];
}
