import React, { useEffect, useState } from "react";
import "./summaryFilterForm.scss";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../DropdownField";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../utils/dropdownUtils";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import DatePickerField from "../DatePickerField";
import ScanTypeService from "../../../services/ScanType/scanType.service";
import { ScanType } from "../../../models/ScanType/scanType.model";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import { SummaryFilterParams } from "../../../models/SummaryFilterParams/summaryFilterParams.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import DoctorService from "../../../services/Doctor/doctor.service";
import { Doctor } from "../../../models/Doctor/doctor.model";

interface SummaryFilterFormProps {
  formValues: SummaryFilterParams;
  onSubmit: (values: SummaryFilterParams) => void;
  onReset: () => void;
  type: UserRoleEnum;
}

function SummaryFilterForm({
  type,
  formValues,
  onSubmit,
  onReset,
}: SummaryFilterFormProps) {
  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const [scanCenterOptions, setScanCenterOptions] = useState<
    IDropdownOptions[]
  >([]);

  useEffect(() => {
    ScanCenterService.fetchScanCenters(
      (scanCenters: ScanCenter[]) => {
        setScanCenterOptions(getDropdownOptions(scanCenters, "name", "id"));
      },
      () => {},
      () => {}
    );
    DoctorService.fetchDoctors(
      (doctors: Doctor[]) => {
        setDoctorOptions(getDropdownOptions(doctors, "doctorName", "id"));
      },
      () => {},
      () => {}
    );
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );
    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div>
      <Formik
        initialValues={formValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 20]} align="middle">
                <Col span={5}>
                  {type === UserRoleEnum.DOCTOR && (
                    <DropdownField
                      placeHolder="Select Scan Center"
                      showSearch
                      title="Scan Center"
                      name="scanCenterId"
                      setFieldValue={setFieldValue}
                      options={scanCenterOptions}
                      value={values.scanCenterId}
                    />
                  )}
                  {type === UserRoleEnum.SCAN_CENTER && (
                    <DropdownField
                      placeHolder="Select Doctor"
                      showSearch
                      title="Doctor"
                      name="doctorId"
                      setFieldValue={setFieldValue}
                      options={doctorOptions}
                      value={values.doctorId}
                    />
                  )}
                </Col>
                <Col span={5}>
                  <DropdownField
                    placeHolder="Select Scan Type"
                    showSearch
                    title="Scan Type"
                    name="scanTypeId"
                    setFieldValue={setFieldValue}
                    options={scanTypeOptions}
                    value={values.scanTypeId}
                  />
                </Col>
                <Col span={5}>
                  <DropdownField
                    placeHolder="Select Body Part"
                    showSearch
                    title="Body Part"
                    name="bodyPartId"
                    setFieldValue={setFieldValue}
                    options={bodyPartOptions}
                    value={values.bodyPartId}
                  />
                </Col>
                <Col span={4}>
                  <DatePickerField
                    title="From Date"
                    name="fromDate"
                    defaultValue={values.fromDate}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={4}>
                  <DatePickerField
                    title="To Date"
                    name="toDate"
                    defaultValue={values.toDate}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={23} className="text-right">
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                  <Button
                    type="default"
                    htmlType="button"
                    className="ml-2"
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SummaryFilterForm;
