import React, { useCallback, useEffect, useState } from "react";
import "./appSidebar.scss";
import { Menu, Tooltip } from "antd";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ActionCable, { Cable, createConsumer } from "actioncable";
import { getHeaders } from "../../../interceptor/axiosInstance";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ProfileService from "../../../services/Profile/profile.service";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { CaseUploadStatusEnum } from "../../../enums/caseUploadStatus.enum";
import { useHistory } from "react-router-dom";
import {
  LeftOutlined,
  MenuUnfoldOutlined,
  DatabaseFilled,
  UserOutlined,
  DiffTwoTone,
  MedicineBoxOutlined,
  TeamOutlined,
  SettingOutlined,
  ProfileOutlined,
} from "@ant-design/icons"; // Import Ant Design icons
import { CaseNotificationEvent } from "../../../enums/CaseNotificationEvent.enum";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { deserialize } from "serializr";

interface AppSidebarProps extends AuthReducerProps, CaseReducerProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

function AppSidebar({
  authenticated,
  userRole,
  user,
  setNewCaseCreated,
  setCaseDetailUpdated,
  collapsed,
  setCollapsed,
}: AppSidebarProps) {
  const adminId = userRole === UserRoleEnum.ADMIN ? user?.id : user?.adminId;
  const history = useHistory();
  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [scanCenter, setScanCenter] = useState<ScanCenter>();
  //   const [collapsed, setCollapsed] = useState(false); // State for sidebar collapse
  //   const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const handleClick = (e: any) => {
    history.push(e.key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed); // Toggle sidebar collapse state
  };

  // const handleResize = () => {
  //   if (window.innerWidth < 1300) {
  //     setCollapsed(true); // Collapse sidebar on small screens
  //   } else {
  //     setCollapsed(false); // Expand sidebar on larger screens
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   // Cleanup on component unmount
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    if (authenticated && userRole === UserRoleEnum.SCAN_CENTER) {
      ProfileService.getProfile(
        userRole,
        (scanCenter: ScanCenter) => {
          setScanCenter(scanCenter);
        },
        () => {},
        () => {}
      );
    }
  }, [authenticated, userRole]);

  useEffect(() => {
    let subscription: any;
    if (userRole && user?.id) {
      const headers = getHeaders();
      const cable = ActionCable.createConsumer(
        `${process.env.REACT_APP_SOCKET_URL}?access-token=${headers["access-token"]}&client=${headers["client"]}&uid=${headers["uid"]}`
      );
      subscription = cable.subscriptions.create(
        {
          channel: "NotificationsChannel",
          room: `notifications:${userRole}: ${user?.id}`,
        },
        {
          connected: () => {
            console.log("connected");
          },
          disconnected: () => {
            console.log("disconnected");
          },
          received: ({
            event_type,
            case_details,
          }: {
            event_type: CaseNotificationEvent;
            case_details: any;
          }) => {
            switch (event_type) {
              case CaseNotificationEvent.NEW_CASE_CREATED: {
                const caseDetail = deserialize(
                  CaseDetail,
                  case_details["case_detail"]
                );
                setNewCaseCreated(caseDetail);
                break;
              }
              case CaseNotificationEvent.CASE_STATUS_UPDATED: {
                const caseDetail = deserialize(
                  CaseDetail,
                  case_details["case_detail"]
                );
                setCaseDetailUpdated(caseDetail);
              }
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [userRole, user]);

  return authenticated ? (
    <div className={`app-sidebar ${collapsed ? "collapsed" : ""}`}>
      {/* Sidebar header with toggle button */}
      <div className="app-sidebar__header">
        {collapsed ? (
          <Tooltip title="Expand Menu">
            <MenuUnfoldOutlined
              onClick={toggleCollapsed}
              className="app-sidebar__toggle collapsed"
            />
          </Tooltip>
        ) : (
          <LeftOutlined
            onClick={toggleCollapsed}
            className="app-sidebar__toggle expanded"
          />
        )}
        {!collapsed && (
          <>
            {adminId === 2 ? (
              <>
                <h1 className="app-sidebar__logo">CONRAD</h1>
                <h5 className="app-sidebar__logo-subtext">TELERADIOLOGY</h5>
              </>
            ) : (
              <h1 className="app-sidebar__logo">RadiolinQ</h1>
            )}
          </>
        )}
        {!collapsed && (
          <div className="app-sidebar__role">
            {userRole?.split("_").join(" ")}
          </div>
        )}
      </div>

      {/* Sidebar Menu */}
      <Menu
        selectedKeys={[pathname]}
        onClick={handleClick}
        defaultSelectedKeys={[AppRoutes.HOME]}
        mode="inline"
        inlineCollapsed={collapsed} // Use collapsed state for the menu
      >
        <Menu.Item key={AppRoutes.HOME} icon={<DatabaseFilled />}>
          <div className="app-sidebar__menu-title">Cases</div>
        </Menu.Item>
        {userRole === UserRoleEnum.ADMIN && (
          <>
            <Menu.Item
              key={AppRoutes.SCAN_CENTRES}
              icon={<MedicineBoxOutlined />}
            >
              <div className="app-sidebar__menu-title">Scan Centers</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.DOCTORS} icon={<TeamOutlined />}>
              <div className="app-sidebar__menu-title">Doctors</div>
            </Menu.Item>
          </>
        )}
        {(userRole === UserRoleEnum.DOCTOR ||
          userRole === UserRoleEnum.ADMIN) && (
          <Menu.Item key={AppRoutes.REPORT_TEMPLATES} icon={<DiffTwoTone />}>
            <div className="app-sidebar__menu-title">Report Templates</div>
          </Menu.Item>
        )}
        <Menu.Item key={AppRoutes.PROFILE} icon={<SettingOutlined />}>
          <div className="app-sidebar__menu-title">Profile</div>
        </Menu.Item>
      </Menu>

      {adminId === 2 && (
        <div className="app-sidebar__footer">
          {!collapsed && (
            <h1>
              <span>Powered by</span> <br />
              RadiolinQ
            </h1>
          )}
        </div>
      )}
    </div>
  ) : null;
}

export default CaseContainer(AuthContainer(AppSidebar));
