import * as Yup from "yup";

export const doctorFormValidation = Yup.object().shape({
  firstName: Yup.string().nullable().required("First name is required!"),
  lastName: Yup.string().nullable().required("Last name is required!"),
  address: Yup.string().nullable(),
  email: Yup.string()
    .nullable()
    .required("Email is required!")
    .email("Email is not valid!"),
  mobileNumber: Yup.string().nullable(),
  stateOfRegistration: Yup.string().nullable(),
  password: Yup.string().nullable(),
  specialisation: Yup.string().nullable(),
  medicalCouncilRegistrationNumber: Yup.string().nullable(),
  assignmentsPerWeek: Yup.number()
    .nullable()
    .required("No of assignments is required!")
    .min(1, "Value should be atleast 1"),
});
