import React, { useEffect, useState } from "react";
import "./doctorProfile.scss";
import AppCard from "../../../shared/components/AppCard";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { Form, Formik } from "formik";
import { doctorFormValidation } from "../../Doctors/DoctorForm/doctorFormValidation";
import { Button, Col, Modal, Row, Switch } from "antd";
import InputField from "../../../shared/components/InputField";
import FileUpload from "../../../shared/components/FileUpload";
import ProfileService from "../../../services/Profile/profile.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AppLoader from "../../../shared/components/AppLoader";
import ChangePasswordForm from "../../../shared/components/ChangePasswordForm";

interface DoctorProfileProps extends AuthReducerProps {}

function DoctorProfile({ userRole }: DoctorProfileProps) {
  const [formValues, setFormValues] = useState(new Doctor());

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleSubmit = (values: Doctor) => {
    const doctor = Object.assign(new Doctor(), values);
    setFormLoading(true);
    ProfileService.updateDoctorProfile(
      doctor,
      (doctor: Doctor) => {
        setFormValues(doctor);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (userRole) {
      setLoading(true);
      ProfileService.getProfile(
        userRole,
        (doctor: Doctor) => {
          setFormValues(doctor);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="doctor-profile">
      <AppCard>
        <h2>Profile</h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={doctorFormValidation}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              isValid,
              dirty,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <InputField
                        title="First Name"
                        type="text"
                        name="firstName"
                        placeholder="Enter First name"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Last Name"
                        type="text"
                        name="lastName"
                        placeholder="Enter Last name"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        readOnly
                        title="Email"
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Mobile"
                        type="text"
                        name="mobileNumber"
                        placeholder="Enter Mobile"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Specialisation"
                        type="text"
                        name="specialisation"
                        placeholder="Enter Specialisation"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="State of registration"
                        type="text"
                        name="stateOfRegistration"
                        placeholder="Enter State of registration"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Medical council registration number"
                        type="text"
                        name="medicalCouncilRegistrationNumber"
                        placeholder="Enter Registration number"
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        title="Address"
                        type="textarea"
                        name="address"
                        placeholder="Enter Address"
                      />
                    </Col>
                    {/* Commented for future use -- Krishna */}
                    {/* <Col span={8}>
                      <div className="doctor-profile__switch">
                        <span>
                          <Switch
                            checked={values?.emailNotification}
                            onChange={(checked) => {
                              setFieldValue("emailNotification", checked);
                            }}
                          />
                        </span>
                        <span>Email Notification</span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="doctor-profile__switch">
                        <span>
                          <Switch
                            checked={values?.telegramNotification}
                            onChange={(checked) => {
                              setFieldValue("telegramNotification", checked);
                            }}
                          />
                        </span>
                        <span>Telegram Notification</span>
                      </div>
                    </Col> */}
                    {/* Commented for future use -- Krishna */}
                    <Col span={8}>
                      <div className="doctor-profile__switch">
                        <span>
                          <Switch
                            checked={values?.addSignatureToReport}
                            onChange={(checked) => {
                              setFieldValue("addSignatureToReport", checked);
                            }}
                          />
                        </span>
                        <span>Add signature to report</span>
                      </div>
                    </Col>
                    <Col span={16} />
                    <Col span={12}>
                      <FileUpload
                        name="doctorSignature"
                        title="Upload Signature"
                        setFieldValue={setFieldValue}
                        value={values.doctorSignature}
                      />
                      {values?.doctorSignatureUrl && (
                        <div className="mt-3">
                          <img
                            src={values?.doctorSignatureUrl}
                            alt="Doctor signature"
                            className="doctor-profile__signature"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="doctor-profile__submit-wrapper text-right mt-3">
                    <Button
                      onClick={() => setShowChangePassword(true)}
                      type="primary"
                      htmlType="button"
                      className="mr-2"
                    >
                      Change Password
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Update Profile
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        <Modal
          destroyOnClose
          visible={showChangePassword}
          className="primary-modal"
          onCancel={() => setShowChangePassword(false)}
          footer={null}
          maskClosable={false}
        >
          <ChangePasswordForm onClose={() => setShowChangePassword(false)} />
        </Modal>
      </AppCard>
    </div>
  );
}

export default AuthContainer(DoctorProfile);
