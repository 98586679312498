import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./doctorSummary.scss";
import DoctorService from "../../../services/Doctor/doctor.service";
import { Table } from "antd";
import { Summary } from "../../../models/Summary/summary.model";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import { SummaryFilterParams } from "../../../models/SummaryFilterParams/summaryFilterParams.model";
import SummaryFilterForm from "../../../shared/components/SummaryFilterForm";
import { UserRoleEnum } from "../../../enums/userRole.enum";

interface DoctorSummaryProps {}

interface DoctorSummaryParams {
  doctorId: string;
}

function DoctorSummary(props: DoctorSummaryProps) {
  const { doctorId }: DoctorSummaryParams = useParams();

  const [loading, setLoading] = useState(false);

  const [doctorSummary, setDoctorSummary] = useState<Summary>();

  const [formValues, setFormValues] = useState<SummaryFilterParams>(
    new SummaryFilterParams()
  );

  const columns = [
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body Part Type",
      dataIndex: "bodyPartName",
      key: "bodyPartName",
    },
    {
      title: "No of cases",
      dataIndex: "count",
      key: "count",
    },
  ];

  const handleSubmit = (values: SummaryFilterParams) => {
    const formValues = Object.assign(new SummaryFilterParams(), values);
    setFormValues(formValues);
    handleShowDoctorSummary(formValues);
  };

  const handleReset = () => {
    const formValues = new SummaryFilterParams();
    setFormValues(formValues);
    handleShowDoctorSummary(formValues);
  };

  const handleShowDoctorSummary = (formValues: SummaryFilterParams) => {
    if (doctorId) {
      setLoading(true);
      DoctorService.showDoctorSummary(
        formValues,
        Number(doctorId),
        (doctorSummary: Summary) => {
          setDoctorSummary(doctorSummary);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleShowDoctorSummary(formValues);
  }, []);

  return (
    <div className="doctor-summary">
      <AppCard>
        <h2>Doctor Summary</h2>
        <div className="doctor-summary__header">
          <SummaryFilterForm
            type={UserRoleEnum.DOCTOR}
            formValues={formValues}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </div>
        {loading ? (
          <AppLoader loading={loading} />
        ) : doctorSummary?.scanTypeBodyPartStats ? (
          <div>
            <div>
              <h3>Total count : {doctorSummary?.totalCount}</h3>
            </div>
            <Table
              dataSource={doctorSummary.scanTypeBodyPartStats}
              columns={columns}
            />
          </div>
        ) : null}
      </AppCard>
    </div>
  );
}

export default DoctorSummary;
