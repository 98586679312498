import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ScanType } from "../../models/ScanType/scanType.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class ScanTypeService {
  static fetchScanTypes(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SCAN_TYPES)
      .then((response) => {
        const scanTypes = deserialize(ScanType, response.data["scan_types"]);
        onSuccess(scanTypes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchScanCenterBranches(
    scanCenterId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SCAN_CENTER_BRANCHES, {
        params: {
          scan_center_id: scanCenterId,
        },
      })
      .then((response) => {
        const scanCenterBranches = deserialize(
          ScanType,
          response.data["scan_center_branches"]
        );
        onSuccess(scanCenterBranches);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showScanType(
    scanTypeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_TYPES + "/" + scanTypeId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const scanType = deserialize(ScanType, response.data["scan_type"]);
        onSuccess(scanType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createScanType(
    scanType: ScanType,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scanTypeJSON = { scan_type: serialize(scanType) };
    axiosInstance
      .post(ApiRoutes.ADMIN_SCAN_TYPES, scanTypeJSON)
      .then((response) => {
        Notification({
          message: "Scan type created",
          type: NotificationTypes.SUCCESS,
        });
        const scanType = deserialize(ScanType, response.data["scan_type"]);
        onSuccess(scanType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateScanType(
    scanType: ScanType,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_SCAN_TYPES + "/" + scanType.id;
    const scanTypeJSON = { scan_type: serialize(scanType) };
    axiosInstance
      .put(API_URL, scanTypeJSON)
      .then((response) => {
        Notification({
          message: "Scan type updated",
          type: NotificationTypes.SUCCESS,
        });
        const scanType = deserialize(ScanType, response.data["scan_type"]);
        onSuccess(scanType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteScanType(
    scanTypeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_TYPES + "/" + scanTypeId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
