import React, { useState } from "react";
import PropTypes from "prop-types";
import "./scanCenterForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { scanCenterFormValidation } from "./scanCenterFormValidation";
import InputField from "../../../shared/components/InputField";
import { Button, Row, Col } from "antd";
import faker from "faker";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";

interface ScanCenterFormProps {
  scanCenter: ScanCenter;
  onSuccess: (scanCenter: ScanCenter) => void;
}

function ScanCenterForm({ scanCenter, onSuccess }: ScanCenterFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const scanCenter = Object.assign(new ScanCenter(), values);
    setFormLoading(true);
    if (scanCenter.id) {
      ScanCenterService.updateScanCenter(
        scanCenter,
        (scanCenter: ScanCenter) => {
          onSuccess(scanCenter);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      scanCenter.password = scanCenter.name;
      ScanCenterService.createScanCenter(
        scanCenter,
        (scanCenter: ScanCenter) => {
          onSuccess(scanCenter);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="scan-center-form">
      <h2 className="text-primary">
        {scanCenter.id ? "Edit Scan Center" : "Add Scan Center"}
      </h2>
      <Formik
        initialValues={scanCenter}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={scanCenterFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[30, 0]}>
                <Col span={12}>
                  <InputField
                    title="Scan centre Name"
                    type="text"
                    name="name"
                    placeholder="Enter name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Mobile"
                    type="text"
                    name="mobileNumber"
                    placeholder="Enter Mobile"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Address"
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                  />
                </Col>
                {!values?.id && (
                  <Col span={12}>
                    <InputField
                      value={values?.name}
                      title="Password"
                      type="text"
                      name="password"
                      placeholder="Your Password"
                    />
                  </Col>
                )}
              </Row>
              <div className="executive-form__submit-wrapper text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  {scanCenter?.id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ScanCenterForm;
