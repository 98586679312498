import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { store } from "../../store";
import {
  AUTHENTICATED,
  SET_USER_ROLE,
  UNAUTHENTICATED,
} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { Doctor } from "../../models/Doctor/doctor.model";
import { Admin } from "../../models/Admin/admin.model";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import { UserRoleEnum } from "../../enums/userRole.enum";

class AuthService {
  static doctorLogin(
    doctor: Doctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorJSON = {
      doctor: serialize(doctor),
    };

    axiosInstance
      .post(ApiRoutes.DOCTOR_LOGIN, doctorJSON)
      .then((response) => {
        const user = deserialize(Doctor, response.data["doctor"]);
        localStorage.setItem("user", JSON.stringify(user));
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user,
          },
        });
        store.dispatch({
          type: SET_USER_ROLE,
          payload: {
            userRole: UserRoleEnum.DOCTOR,
          },
        });
        Notification({
          message: "Login",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static adminLogin(
    admin: Admin,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const adminJSON = {
      admin: serialize(admin),
    };
    axiosInstance
      .post(ApiRoutes.ADMIN_LOGIN, adminJSON)
      .then((response) => {
        const admin = deserialize(Admin, response.data["admin"]);
        localStorage.setItem("user", JSON.stringify(admin));
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            admin,
            user: admin,
          },
        });
        store.dispatch({
          type: SET_USER_ROLE,
          payload: {
            userRole: UserRoleEnum.ADMIN,
            adminType: admin.adminType,
          },
        });
        Notification({
          message: "Login",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Login failed",
          description: "incorrect email or password",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static scanCenterLogin(
    scanCenter: ScanCenter,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scanCenterJSON = {
      scan_center: serialize(scanCenter),
    };
    axiosInstance
      .post(ApiRoutes.SCAN_CENTER_LOGIN, scanCenterJSON)
      .then((response) => {
        const user = deserialize(ScanCenter, response.data["scan_center"]);
        localStorage.setItem("user", JSON.stringify(user));
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user,
          },
        });
        store.dispatch({
          type: SET_USER_ROLE,
          payload: {
            userRole: UserRoleEnum.SCAN_CENTER,
          },
        });
        Notification({
          message: "Login",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static getUserRole(
    email: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.USER_ROLE, {
        params: {
          email,
        },
      })
      .then((response) => {
        onSuccess(response.data["role"], response.data["type"]);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static logout(
    url: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .delete(url)
      .then((response) => {
        localStorage.clear();
        onSuccess();
        store.dispatch({
          type: UNAUTHENTICATED,
          payload: {
            authenticated: false,
          },
        });
        store.dispatch({
          type: SET_USER_ROLE,
          payload: {
            userRole: undefined,
            adminType: undefined,
          },
        });
        Notification({
          message: "Logged out!",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {
        localStorage.clear();
        store.dispatch({
          type: UNAUTHENTICATED,
          payload: {
            authenticated: false,
          },
        });
        store.dispatch({
          type: SET_USER_ROLE,
          payload: {
            userRole: undefined,
            adminType: undefined,
          },
        });
        Notification({
          message: "Logged out!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .finally(onFinal);
  }
}

export default AuthService;
