import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "../../views/Auth/LoginForm";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";

const authRouter = () => {
  return (
    <Switch>
      <Redirect exact strict from={AppRoutes.AUTH} to={AppRoutes.LOGIN} />
      <Route exact path={AppRoutes.LOGIN} component={() => <LoginForm />} />
    </Switch>
  );
};

export default authRouter;
