import React from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "./styles/_main.scss";
import { AUTHENTICATED } from "./store/definitions/authConstants";

let auth, user;

if (localStorage.getItem("auth")) {
  auth = JSON.parse(localStorage.getItem("auth") || "");
  user = JSON.parse(localStorage.getItem("user") || "{}");
}

if (auth && user) {
  store.dispatch({
    type: AUTHENTICATED,
    payload: {
      authenticated: true,
      user,
    },
  });
}

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes />
      </div>
    </Provider>
  );
};

export default App;
