import { deserialize, serialize } from "serializr";
import axiosInstance from "../../../interceptor/axiosInstance";
import Notification from "../../../shared/components/Notification";
import ApiRoutes from "../../../routes/routeConstants/apiRoutes";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { DicomData } from "../../../models/DicomData/dicomData.model";
import { DicomDataThumbnail } from "../../../models/DicomDataThumbnail/dicomDataThumbnail.model";

export default class AdminCaseService {
  static fetchAdminCases(
    filterParams: CaseFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    axiosInstance
      .get(ApiRoutes.ADMIN_CASES, {
        params,
      })
      .then((response) => {
        const adminCases = deserialize(
          CaseDetail,
          response.data["case_details"]
        );
        onSuccess(adminCases);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static assignCase(
    selfAssign: boolean,
    caseDetail: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const caseDetailJSON = { case_detail: serialize(caseDetail) };
    let API_URL = selfAssign
      ? ApiRoutes.ADMIN_SELF_ASSIGN
      : ApiRoutes.ADMIN_ASSIGN_TO_DOCTOR;
    API_URL = API_URL.replace(
      ":caseDetailId",
      caseDetail?.id?.toString() || ""
    );
    axiosInstance
      .post(API_URL, caseDetailJSON)
      .then((response) => {
        Notification({
          message: selfAssign
            ? "Case has been assigned to you!"
            : "Case has been assigned to the doctor",
          type: NotificationTypes.SUCCESS,
        });
        const adminCase = deserialize(CaseDetail, response.data["case_detail"]);
        onSuccess(adminCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAdminCase(
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_CASES + "/" + adminCaseId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const adminCase = deserialize(CaseDetail, response.data["case_detail"]);
        onSuccess(adminCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCaseViews(
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CASE_VIEW_ACCESS.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const doctors = deserialize(Doctor, response.data["doctors"]);
        onSuccess(doctors);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCase(
    caseDetail: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const caseDetailJSON = { case_detail: serialize(caseDetail) };
    const API_URL = ApiRoutes.ADMIN_CASE_UPDATE.replace(
      ":caseDetailId",
      caseDetail?.id?.toString() || ""
    );
    axiosInstance
      .put(API_URL, caseDetailJSON)
      .then((response) => {
        const caseDetail = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        Notification({
          message: "Case details updated",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(caseDetail);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCaseViews(
    doctors: Doctor[],
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorJSON = { case_detail: { doctors: serialize(doctors) } };
    const API_URL = ApiRoutes.CASE_VIEW_ACCESS.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    axiosInstance
      .put(API_URL, doctorJSON)
      .then((response) => {
        Notification({
          message: "View access provided",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAdminCase(
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_DICOM_DOWNLOAD.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data["url"]);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAdminCase(
    adminCase: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_CASES + "/" + adminCase.id;
    const adminCaseJSON = serialize(adminCase);
    axiosInstance
      .put(API_URL, adminCaseJSON)
      .then((response) => {
        const adminCase = deserialize(CaseDetail, response.data["case_detail"]);
        onSuccess(adminCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAdminCaseDirectories(
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_CASE_THUMBNAILS.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((res) => {
        const dicomDataThumbnails = deserialize(
          DicomDataThumbnail,
          res.data as any[]
        );
        onSuccess(dicomDataThumbnails);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAdminCaseDirectoryFiles(
    adminCaseId: number,
    folder: string
  ): Promise<any> {
    const API_URL = ApiRoutes.ADMIN_CASE_DIRECTORY_FILES.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    return axiosInstance.get(API_URL, {
      params: {
        folder,
      },
    });
    /*.then((res) => {
        const dicomFiles = deserialize(DicomData, res.data as any[]);
        onSuccess(dicomFiles);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });*/
  }
}
