import { serializable, alias, primitive } from "serializr";

export class Report {
  @serializable(alias("id", primitive()))
  id?: number;

  reportTemplateId?: number;

  @serializable(alias("content", primitive()))
  content?: string;

  @serializable(alias("case_detail_id", primitive()))
  caseDetailId?: number;

  @serializable(alias("assignment_id", primitive()))
  assignmentId?: number;

  @serializable(alias("status", primitive()))
  status?: boolean;
}
