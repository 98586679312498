import React from "react";
import AuthRouter from "../authRouters";
import logo from "../../../assets/images/telerad-logo.png";
import "./authWrapper.scss";

const AuthWrapper = () => {
  const { origin } = window.location;
  const isConrad = origin.includes("conrad.radiolinq.com");
  // const isConrad = true;
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__logo-section">
        <div className="auth-wrapper__logo-section-content">
          <h1 className="auth-wrapper__logo">
            {isConrad ? "CONRAD" : "RadiolinQ"}
          </h1>
          <h5 className="auth-wrapper__logo-subtext">
            {isConrad ? "TELERADIOLOGY" : "Imaging Anytime Anywhere"}
          </h5>
          {isConrad && (
            <h6 className="auth-wrapper__powered-by-text">
              <span>Powered by</span>
              <span>RadiolinQ</span>
            </h6>
          )}
        </div>
      </div>
      <div className="auth-wrapper__auth-section">
        <AuthRouter />
      </div>
    </div>
  );
};

export default AuthWrapper;
