import * as Yup from "yup";

export const caseFormValidation = Yup.object().shape({
  patientId: Yup.string().nullable().required("Patient ID is required!"),
  patientName: Yup.string().nullable().required("Patient name is required!"),
  age: Yup.number()
    .nullable()
    .required("Age is required!")
    .min(1, "Age should be 1-100")
    .max(100, "Age should be 1-100"),
  gender: Yup.string().nullable().required("Gender is required!"),
  currentClinicalProblem: Yup.string().nullable(),
  referringDoctor: Yup.string().nullable(),
  patientHistory: Yup.string().nullable(),
  contrast: Yup.boolean().nullable().required("Contrast is required!"),
  scanTypeId: Yup.number().nullable().required("Scan type is required!"),
  bodyPartIds: Yup.array().min(1, "Atleast one body part should be selected"),
});

export const caseFileValidation = Yup.object().shape({
  zipFile: Yup.mixed().required("Zip file is required"),
});
