import { serializable, alias, primitive } from "serializr";

export class SummaryFilterParams {
  @serializable(alias("scan_center_id", primitive()))
  scanCenterId?: number;

  @serializable(alias("doctor_id", primitive()))
  doctorId?: number;

  @serializable(alias("from_date", primitive()))
  fromDate?: string;

  @serializable(alias("to_date", primitive()))
  toDate?: string;

  @serializable(alias("scan_type_id", primitive()))
  scanTypeId?: number;

  @serializable(alias("body_part_id", primitive()))
  bodyPartId?: number;
}
