import React, { useEffect, useState } from "react";
import "./caseEditForm.scss";
import { CaseDetail } from "../../../../models/CaseDetail/caseDetail.model";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../../../shared/utils/dropdownUtils";
import ScanTypeService from "../../../../services/ScanType/scanType.service";
import { ScanType } from "../../../../models/ScanType/scanType.model";
import BodyPartService from "../../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../../models/BodyPart/bodyPart.model";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import AdminCaseService from "../../../../services/Case/AdminCase/adminCase.service";
import { CaseBodyPart } from "../../../../models/CaseBodyPart/caseBodyPart.model";
import InputField from "../../../../shared/components/InputField";

interface CaseEditFormProps {
  caseDetail: CaseDetail;
  onSuccess: (caseDetail: CaseDetail) => void;
}

function CaseEditForm({ caseDetail, onSuccess }: CaseEditFormProps) {
  const [formValues, setFormValues] = useState<CaseDetail>(caseDetail);

  const [formLoading, setFormLoading] = useState(false);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const contrastOptions: IDropdownOptions[] = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const handleSubmit = (values: FormikValues) => {
    const caseDetail = Object.assign(new CaseDetail(), values);
    caseDetail.caseBodyParts = caseDetail.caseBodyPartIds.map((bodyPartId) =>
      Object.assign(new CaseBodyPart(), {
        bodyPartId,
      })
    );
    setFormLoading(true);
    AdminCaseService.updateCase(
      caseDetail,
      (caseDetail: CaseDetail) => {
        onSuccess(caseDetail);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (caseDetail && caseDetail?.caseBodyParts) {
      caseDetail.caseBodyPartIds = caseDetail.caseBodyParts.map(
        (bodyPart) => bodyPart.bodyPartId || 0
      );
      setFormValues(
        Object.assign(new CaseDetail(), {
          ...caseDetail,
        })
      );
    }
  }, [caseDetail]);

  return (
    <div className="case-edit-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <DropdownField
                    placeHolder="Select Scan Type"
                    className="mb-0"
                    showSearch
                    title="Scan Type"
                    name="scanTypeId"
                    setFieldValue={setFieldValue}
                    options={scanTypeOptions}
                    value={values.scanTypeId}
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    className="mb-0"
                    placeHolder="Select Body parts"
                    showSearch
                    mode={DropdownModeEnum.MULTIPLE}
                    title="Body parts"
                    name="caseBodyPartIds"
                    setFieldValue={setFieldValue}
                    options={bodyPartOptions}
                    value={values.caseBodyPartIds}
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    className="mb-0"
                    placeHolder="Select Contrast"
                    showSearch
                    title="Contrast"
                    name="contrast"
                    setFieldValue={setFieldValue}
                    options={contrastOptions}
                    value={values.contrast}
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    title="Current clinical problem"
                    type="text"
                    name="currentClinicalProblem"
                    placeholder="Enter current clinical problem"
                  />
                </Col>
              </Row>
              <div className="case-assignment-form__submit-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={
                    formLoading ||
                    !values?.scanTypeId ||
                    values?.caseBodyPartIds?.length === 0
                  }
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CaseEditForm;
