import React from "react";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Button } from "antd";
import "./fileUpload.scss";

interface FileUploadProps {
  name: string;
  title: string;
  setFieldValue: Function;
  value?: File;
  accept?: string;
}

function FileUpload(props: FileUploadProps) {
  let inputRef: any;

  const { name, title, setFieldValue, value, accept } = props;

  const handleClick = () => {
    inputRef.click();
  };

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    if (setFieldValue) {
      setFieldValue(name, file);
    }
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="file-upload">
          {title && <div className="file-upload__title">{title}</div>}
          <div>
            {value && <div className="mt-2 mb-2">{value.name}</div>}
            <Button
              type="dashed"
              className="file-upload__input"
              onClick={handleClick}
            >
              Attach File
            </Button>
            <input
              ref={(ref) => (inputRef = ref)}
              type="file"
              multiple
              onChange={handleChange}
              accept={accept ?? "image/jpeg,image/png,image/jpg"}
              style={{ display: "none" }}
            />
          </div>
          <ErrorMessage name={name}>
            {(message) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default FileUpload;
