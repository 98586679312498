import React, { useEffect, useState } from "react";
import "./scanCenterProfile.scss";
import AppCard from "../../../shared/components/AppCard";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ProfileService from "../../../services/Profile/profile.service";
import { Form, Formik } from "formik";
import { scanCenterFormValidation } from "../../ScanCenters/ScanCenterForm/scanCenterFormValidation";
import { Button, Col, Modal, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import AppLoader from "../../../shared/components/AppLoader";
import ChangePasswordForm from "../../../shared/components/ChangePasswordForm";

interface ScanCenterProfileProps extends AuthReducerProps {}

function ScanCenterProfile({ userRole }: ScanCenterProfileProps) {
  const [formValues, setFormValues] = useState(new ScanCenter());

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleSubmit = (values: ScanCenter) => {
    const scanCenter = Object.assign(new ScanCenter(), values);
    setFormLoading(true);
    ProfileService.updateScanCenterProfile(
      scanCenter,
      (scanCenter: ScanCenter) => {
        setFormValues(scanCenter);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (userRole) {
      setLoading(true);
      ProfileService.getProfile(
        userRole,
        (scanCenter: ScanCenter) => {
          setFormValues(scanCenter);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="scan-center-profile">
      <AppCard>
        <h2>Profile</h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={scanCenterFormValidation}
          >
            {({ isValid }) => {
              return (
                <Form>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <InputField
                        title="Scan centre Name"
                        type="text"
                        name="name"
                        placeholder="Enter name"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        readOnly
                        title="Email"
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Mobile"
                        type="text"
                        name="mobileNumber"
                        placeholder="Enter Mobile"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Address"
                        type="text"
                        name="address"
                        placeholder="Enter Address"
                      />
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      onClick={() => setShowChangePassword(true)}
                      type="primary"
                      htmlType="button"
                      className="mr-2"
                    >
                      Change Password
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Update Profile
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        <Modal
          destroyOnClose
          visible={showChangePassword}
          className="primary-modal"
          onCancel={() => setShowChangePassword(false)}
          footer={null}
          maskClosable={false}
        >
          <ChangePasswordForm onClose={() => setShowChangePassword(false)} />
        </Modal>
      </AppCard>
    </div>
  );
}

export default AuthContainer(ScanCenterProfile);
