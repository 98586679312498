import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as CaseActions from "../actions/caseActions";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => ({
  ...ownProps,
  pendingCaseIds: state.caseDetail.pendingCaseIds,
  completedCaseIds: state.caseDetail.completedCaseIds,
  uploadStartedCaseIds: state.caseDetail.uploadStartedCaseIds,
  newCaseDetail: state.caseDetail.newCaseDetail,
  updatedCaseDetail: state.caseDetail.updatedCaseDetail,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(CaseActions, dispatch);

const CaseContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default CaseContainer;
